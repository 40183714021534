import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'account',
  setup: function setup(__props) {
    var restrictResponse = process.env.USE_TIERS === "true";
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      idtoken = _useBaseUtils.idtoken,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var showSubscriptions = computed(function () {
      return restrictResponse;
    });
    return {
      __sfc: true,
      restrictResponse: restrictResponse,
      dev: dev,
      idtoken: idtoken,
      refCode: refCode,
      I18nFn: I18nFn,
      showSubscriptions: showSubscriptions
    };
  }
};